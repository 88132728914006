@charset "utf-8";
/* CSS Document */

/* ++++右側iframeに(外部リンクがあるためlineのもの) +++++++++++ */

iframe.sb_r{
	display:block;
	width:130px;
	height:650px;
	position:fixed;
	top:140px;
	right:0px;
	z-index:99;
}
@media screen and (max-width: 1260px) {
/*
*/
iframe.sb_r { display:none!important; }
}



iframe.m_count{
	display:none; /* 非表示　*/
	margin:20px auto 0 auto;
}

iframe.ss_count{
	display:none; /* 非表示　*/
	margin:20px auto 0 auto;
}


iframe.h_event{
	display:block; /* 非表示　*/
	width:1110px;
	height:360px;/* 140  400  1 120 2 200 スライド時120*/
	margin:0 auto 40px auto;
}


iframe.sb_b_sp{
	display:block; /* 非表示　*/
	width:95%;
	z-index:9999;
	margin:0 auto;
}


/* 動画回避用*/
#itemDescription{position:static;}


iframe.sb_b_sp{ height:220px;}

/*
@media screen and (max-width: 400px) {
iframe.sb_b_sp{ height:120px;}
.shopname-info{ margin:0 auto 120px auto; }
}
@media screen and (max-width: 330px) {
iframe.sb_b_sp{ height:120px;}
.shopname-info{ margin:0 auto 120px auto; }
}
@media screen and (max-width: 340px) {
iframe.sb_b_sp{ height:120px;}
.shopname-info{ margin:0 auto 120px auto; }
}
*/








/* +++++++++++++++++++++++ */
/* sp_area_01 元から入っていたエリア　文字数オーバーのためcssに変換　*/
/* +++++++++++++++++++++++ */
.sp_area_01 img{
	width:100%;
}











/* +++++++++++++++++++++++ */
/* iframe内css　*/
/* +++++++++++++++++++++++ */

.event_01 {
    width: 1110px;
	padding:10px 0;
    margin: 0 auto;
}
.event_01 img.info_01{
	width:1160px;
}
.pc .event_01 .slides li{
	margin:0 20px 0 0!important;
}
.event_01 img{
	width:100%;
}

.sp .event_01 a{ display:block; margin:0 0 3px 0;}

table.b_2{ width:100%;}
.b_2 td{ width:49%;}
.b_2 th{ width:1%;}

table.b_1{ width:100%;}
.b_1 img{ width:100%;}
.b_1 td{ width:100%;}
.b_1 th{ width:0%;}



.info_01 {
	display:block;
	width:1200px;
	margin:20px auto;
}




/* +++++++++++++++++++++++ */
/* pc sp切替　*/
/* +++++++++++++++++++++++ */

.pc{ display:block;}
.sp{ display:none;}

@media screen and (max-width: 480px) {
.pc{ display:none!important;}
.sp{ display:block!important;}
.event_01{width: 100%!important;}
}



